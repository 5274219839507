import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthService from '@services/AuthService';
import type { PartnerCompanyProps } from '@services/PartnerCompanyService/dtos';
import CompanyService from '@services/CompanyService';
import FinancialService from '@services/FinancialService';
import { UserRedirectsEnum } from '@services/UserService';
import BeneficiaryService from '@services/BeneficiaryService';

import { BaseProfileResourceEnum, CompanyStatusEnum } from '@constants';
import { type AccountProps, UserType } from '@contexts';
import { CompanySetupDetailsRouter } from '@flows/private/companySetup/pages/CompanySetupDetails/router';
import { HomeRouter } from '@flows/private/home/router';
import { useAuth, useProfileDetails, useScreenLoading } from '@hooks';
import { toastifyApiErrors } from '@utils';
import { useCompanySetupListState } from '@flows/private/companySetup/pages/CompanySetupList/hooks';

export function useSelectPartnerCompany() {
  const { updateAccount, selectedCompany, user } = useAuth();
  const { refreshCompanySetupListStates } = useCompanySetupListState();
  const { getUserProfileDetails } = useProfileDetails();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { onScreenLoading } = useScreenLoading();

  const handleCreateSession = useCallback(
    async (partnerCompany: string, companyId: string) => {
      try {
        const authService = new AuthService();
        await authService.createSession({ partnerCompany, companyId });
        return true;
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
        return false;
      }
    },
    [enqueueSnackbar],
  );

  const handleRemoveFromNewCompanies = useCallback(
    async (companyId: string) => {
      try {
        const companyService = new CompanyService();
        await companyService.removeIsNewCompanyStatus({ id: companyId });
        refreshCompanySetupListStates();
      } catch (error) {
        toastifyApiErrors(error, enqueueSnackbar);
      }
    },
    [],
  );

  const handleMonthlyFeePreviewReport = useCallback(async () => {
    try {
      const financialService = new FinancialService();
      return await financialService.getMonthlyFeePreviewReport();
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      return undefined;
    }
  }, []);

  const handleCoparticipationPreviewReport = useCallback(async () => {
    try {
      const financialService = new FinancialService();
      return await financialService.getCoparticipationPreviewReport();
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      return undefined;
    }
  }, []);

  const handleSuspendedBeneficiary = useCallback(async () => {
    try {
      const beneficiaryService = new BeneficiaryService();
      return await beneficiaryService.getSuspendedBeneficiary();
    } catch (error) {
      toastifyApiErrors(error, enqueueSnackbar);
      return undefined;
    }
  }, []);

  const handleSelectPartnerCompany = useCallback(
    async (selectedPartnerCompany?: PartnerCompanyProps) => {
      if (!selectedPartnerCompany || !selectedCompany) {
        updateAccount({ selectedPartnerCompany: undefined });
        return;
      }

      const { profile } = selectedPartnerCompany;
      const { id = '' } = profile || {};

      onScreenLoading(true);

      const userProfileDetails = await getUserProfileDetails(id);

      const resources = userProfileDetails?.resources;

      const combinedProfile = profile && {
        ...profile,
        resources,
      };

      await handleCreateSession(
        selectedPartnerCompany.partnerCompany,
        selectedCompany.id,
      );

      const updatedData: AccountProps = {
        selectedPartnerCompany: {
          ...selectedPartnerCompany,
          profile: combinedProfile,
        },
      };

      const hasFinancialResource =
        resources && resources[BaseProfileResourceEnum.FINANCIAL];

      if (hasFinancialResource) {
        const monthlyFeePreviewReport = await handleMonthlyFeePreviewReport();

        const coparticipationPreviewReport =
          await handleCoparticipationPreviewReport();

        updatedData.optionalRedirects = {
          ...updatedData.optionalRedirects,
          [UserRedirectsEnum.MONTHLY_FEE_PREVIEW_REPORT]: Boolean(
            monthlyFeePreviewReport?.previewReport,
          ),
          [UserRedirectsEnum.COPARTICIPATION_PREVIEW_REPORT]: Boolean(
            coparticipationPreviewReport?.coparticipationPreviewReport,
          ),
        };
      }

      const hasBeneficiaryResource =
        resources && resources[BaseProfileResourceEnum.BENEFICIARIES];

      if (hasBeneficiaryResource) {
        const suspendedBeneficiaryReport = await handleSuspendedBeneficiary();
        updatedData.optionalRedirects = {
          ...updatedData.optionalRedirects,
          [UserRedirectsEnum.SUSPENDED_BENEFICIARY]: Boolean(
            suspendedBeneficiaryReport?.hasSuspendedBeneficiaryReport,
          ),
        };
      }

      updateAccount(updatedData);

      const backofficeUser = user?.companyType === UserType.Backoffice;
      const isRegisterAccessCompany =
        selectedCompany?.status === CompanyStatusEnum.REGISTER_ACCESS;

      if (selectedCompany?.isNewCompany) {
        handleRemoveFromNewCompanies(selectedCompany.id);
      }

      onScreenLoading(false);

      if (backofficeUser && isRegisterAccessCompany) {
        navigate(
          CompanySetupDetailsRouter.dynamicPath({
            companyId: selectedCompany.id,
          }),
        );
        return;
      }

      navigate(HomeRouter.path);
    },
    [handleRemoveFromNewCompanies, navigate, user, selectedCompany],
  );

  return { handleSelectPartnerCompany };
}
