import { useMemo } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';

import { COLORS, Icon, Mask } from '@hapvida/hapvida-core-components';

import {
  USER_STATUS_ICON_NAME,
  USER_STATUS_COLOR,
  USER_STATUS_NAME,
  USER_COMPANY_TYPE_NAME,
  UserStatusEnum,
} from '@constants';
import { ReprocessButton } from 'components/ReprocessButton/styles';
import { useReprocessUserBackoffice } from '@flows/private/backofficeUser/pages/BackofficeUserList/hooks/useReprocessUserBackoffice/useReprocessUserBackoffice';

import { BackofficeUserListColumnNameEnum } from '../../../constants';
import type { UseUserBackofficeListTableRowsProps } from './types';

export function useUserBackofficeListTableRows({
  users,
}: UseUserBackofficeListTableRowsProps) {
  const { loadingState, handleReprocessUser } = useReprocessUserBackoffice();

  const userBackofficeListTableRows = useMemo(
    () =>
      users?.map(user => {
        const isError = user?.status === UserStatusEnum?.ERROR;
        const isProcessing = user?.status === UserStatusEnum?.PROCESSING;

        return {
          id: user?.id,
          errorComponent: isError && (
            <Stack
              flex={1}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color={COLORS?.PRIMARY.RED?.['+1']} variant="text">
                {user?.notification}
              </Typography>
              {user?.allowResend && (
                <ReprocessButton
                  disabled={loadingState}
                  onClick={() => handleReprocessUser(user?.id)}
                  endIcon={<Icon name="refresh" size={16} />}
                  variant="contained"
                  color="secondary"
                  size="small"
                >
                  Reprocessar
                </ReprocessButton>
              )}
            </Stack>
          ),
          itHasError: isError,
          showEditButton: !isProcessing,
          main: {
            [BackofficeUserListColumnNameEnum?.PERSONAL_DATA]: (
              <Stack direction="row" alignItems="center" gap={2}>
                <Avatar
                  alt={user?.name}
                  src={user?.name}
                  sx={{ width: 32, height: 32 }}
                />
                <Stack>
                  <Typography variant="subtitle1">{user?.name}</Typography>
                  {user?.document && (
                    <Typography variant="body2">
                      {Mask.cpf(user?.document) ?? '-'}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            ),
            [BackofficeUserListColumnNameEnum?.COMPANY_TYPE]: (
              <Typography fontWeight={700}>
                {USER_COMPANY_TYPE_NAME[user?.companyType]}
              </Typography>
            ),
            [BackofficeUserListColumnNameEnum?.EMAIL]: (
              <Typography overflow="hidden" textOverflow="ellipsis">
                {user?.email}
              </Typography>
            ),
            [BackofficeUserListColumnNameEnum?.STATUS]: (
              <Stack direction="row" alignItems="center" gap={1}>
                <Icon
                  name={USER_STATUS_ICON_NAME[user?.status]}
                  color={USER_STATUS_COLOR[user?.status]}
                  size={20}
                />
                <Typography
                  color={USER_STATUS_COLOR[user?.status]}
                  fontWeight={600}
                >
                  {user?.status ? USER_STATUS_NAME[user?.status] : '-'}
                </Typography>
              </Stack>
            ),
          },
          dependents: [],
        };
      }) ?? [],
    [users, loadingState],
  );

  return { loadingState, userBackofficeListTableRows };
}
