import {
  PathMatch,
  useMatch,
  useNavigate,
  useResolvedPath,
  matchPath,
} from 'react-router-dom';

import { Sidebar as SidebarCore } from '@hapvida/hapvida-core-components';

import type { SubRouteProps } from 'routes/types';
import { useSidebarModalState } from '@hooks';
import { useRoutes } from 'templates/private/components/Sidebar/hooks';

import type { RouteGroupProps } from './types';

export function RouteGroup({
  route,
  match,
  isDisabled,
  stackProps,
}: Readonly<RouteGroupProps>) {
  const { name, path, Icon, subRoutes = [] } = route;
  const navigate = useNavigate();
  const { handleRouteDisabled } = useRoutes();
  const { handleShowModal, currentModalPath } = useSidebarModalState();
  const currentRoute = window.location.pathname;

  const hasSubRouteSelected = subRoutes.some(subRoute => {
    const subRoutePath = subRoute.path ?? '';
    const subRouteMatch = matchPath(subRoutePath, currentRoute);

    return Boolean(subRoute.path) && Boolean(subRouteMatch);
  });

  const onClickSubRoute = (
    subRoute: SubRouteProps,
    subRouteMatch: PathMatch<string> | null,
  ) => {
    if (subRoute.sidebarModalProps) {
      handleShowModal(subRoute.sidebarModalProps, subRoute.path);
      return;
    }

    if (subRouteMatch?.pathname === currentRoute) {
      window.location.reload();
    } else if (!isDisabled) {
      const subRoutePath = subRoute.path ?? '';
      navigate(subRoutePath);
    }
  };

  const items = subRoutes.map(subRoute => {
    const isSubDisabled = handleRouteDisabled(subRoute);

    const subRouteResolved = useResolvedPath({ pathname: subRoute.path });
    const subRouteMatch = useMatch({
      path: subRouteResolved.pathname,
      end: false,
    });

    const isModalOpen = Boolean(currentModalPath);

    const isOnThisRoute = Boolean(subRoute.path) && Boolean(subRouteMatch);

    const isSelected = !isModalOpen && isOnThisRoute;

    const isModalSelected = isModalOpen && currentModalPath === subRoute.path;

    return {
      label: subRoute.name,
      disabled: isSubDisabled,
      isSelected: isSelected || isModalSelected,
      onClick: () => onClickSubRoute(subRoute, subRouteMatch),
    };
  });

  return (
    <SidebarCore.ItemGroup
      {...stackProps}
      iconName={Icon?.props.name}
      isSelected={Boolean(match) || hasSubRouteSelected}
      disabled={isDisabled}
      label={name}
      key={path}
      items={items}
    />
  );
}
