import { Stack } from '@mui/material';

import {
  ErrorLoadingPage,
  FilterSummary,
  Loading,
  NoSearchResults,
} from '@hapvida/hapvida-core-components';

import { useFilterParams } from '@hooks';

import { useMovementsState } from './hooks';
import { EmptyMovements } from './components/EmptyMovements';
import { SearchResult } from './components/SearchResult';

export function MovementListLayout() {
  const {
    movementsState,
    movementsSummaryState,
    reviewsScreenMovementState,
    refreshMovementsStates,
  } = useMovementsState();
  const { hasFilters } = useFilterParams();

  if (movementsState?.isLoading || reviewsScreenMovementState?.isLoading) {
    return (
      <Loading
        padding={8}
        message={
          <>
            Estamos carregando as informações. <br /> Em breve, tudo estará
            pronto para você
          </>
        }
      />
    );
  }

  if (movementsState?.isError || movementsSummaryState?.isError) {
    return <ErrorLoadingPage onReloadClick={refreshMovementsStates} />;
  }

  const isEmpty = movementsState?.data?.movements?.length === 0 && !hasFilters;
  if (isEmpty) {
    return <EmptyMovements />;
  }

  const isSearchResultEmpty = movementsState?.data?.movements?.length === 0;
  const totalMovementsLength = movementsState?.data?.total ?? 0;
  const label = totalMovementsLength === 1 ? 'movimentação' : 'movimentações';
  const filterSummaryLabel = `${totalMovementsLength} ${label}`;

  return (
    <Stack paddingTop={3} gap={3}>
      <FilterSummary totalLabel={filterSummaryLabel} />

      {isSearchResultEmpty ? (
        <NoSearchResults padding={5} />
      ) : (
        <SearchResult movementList={movementsState?.data} />
      )}
    </Stack>
  );
}
